<template>
  <div class="section-body">
    <b-container fluid="true">
      <b-row>
        <b-col sm="12" lg="9">
          <b-carousel
              id="carousel-1"
              v-model="slide"
              controls
              indicators
              img-width="1024"
              img-height="576"
              style="text-shadow: 1px 1px 2px #333;"
          >
            <!-- Text slides with image -->
            <b-carousel-slide caption="Checkout our new displays!">
              <template #img>
                <img
                    src="baseball-gloves-4.jpg"
                    alt="image slot"
                    :height="carouselImageHeight"
                >
              </template>
            </b-carousel-slide>

            <!-- Slides with custom text -->
            <b-carousel-slide caption="Learn about the Conlin cabin" text="And the family behind it's preservation">
              <template #img>
                <img
                    src="cabin-with-conlin-family-1.jpg"
                    alt="image slot"
                    :height="carouselImageHeight"
                >
              </template>
            </b-carousel-slide>

            <!-- Slides with img slot -->
            <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
            <b-carousel-slide caption="Our office will be reopening soon. Stay tuned!">
              <template #img>
                <img
                    src="office-open.jpg"
                    alt="image slot"
                    :height="carouselImageHeight"
                >
              </template>
            </b-carousel-slide>

          </b-carousel>
          <br/>
        </b-col>
        <b-col sm="12" lg="3">
          <div id="eventCol" ref="eventCol">
            <events />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Events from '@/components/Events.vue'
export default {
  name: 'HelloWorld',
  components: { Events },
  data () {
    return {
      slide: 0,
      sliding: null,
      carouselImageHeight: 576
    }
  },
  created () {
    window.addEventListener('resize', this.myEventHandler)
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  mounted () {
    this.myEventHandler(null)
  },
  methods: {
    myEventHandler (e) {
      this.carouselImageHeight = window.innerHeight * 0.75

      if (this.carouselImageHeight < this.$refs.eventCol.clientHeight) {
        this.carouselImageHeight = this.$refs.eventCol.clientHeight
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.carousel-item img {
  width: 100%;
  object-fit: cover;
}

</style>
