<template>
  <div id="app">
    <primary-nav/>
  </div>
</template>

<script>
import PrimaryNav from '@/views/PrimaryNav.vue'
export default {
  components: { PrimaryNav }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

  #nav {
  padding: 30px;
}

  #nav a {
  font-weight: bold;
  color: #2c3e50;
}
  #nav a.visted {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active {
  transition: opacity 0.25s ease-in;
}

.fade-enter-leave {
  transition: opacity 0.25s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.section-body {
  padding: 4px;
}

</style>
