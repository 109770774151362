<template>
  <div>
    <b-container fluid="true"
                 :style="{
                            'background': 'linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(' + require('../assets/header-background-spring-valley-2.jpg') + ')',
                            'background-repeat': 'repeat',
                            'background-position': 'bottom left',
                            'object-fit': 'cover'
                }">
      <b-row>
        <b-col sm="3">
          <router-link to="/">
            <img alt="PCHA logo" src="pcha-new-logo.png">
          </router-link>
        </b-col>
        <b-col sm="9" style="color: black;">
          <div>
            <br/>
            <br/>
            <i>OUR MISSION IS TO DISCOVER, COLLECT, PRESERVE, AND SHARE THE HERITAGE OF PIERCE COUNTY.</i>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-navbar toggleable="lg" style="background-color: wheat" :sticky=true fixed="top">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" class="nav-links" v-if="$route.name !== 'Home'">Home</b-nav-item>
          <b-nav-item to="/about" class="nav-links">About Us</b-nav-item>
          <b-nav-item to="/collections" class="nav-links">Collections</b-nav-item>
          <b-nav-item to="/" class="nav-links">County History</b-nav-item>
          <b-nav-item to="/" class="nav-links">History Sites</b-nav-item>
          <b-nav-item to="/" class="nav-links">Calendar</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#" class="nav-links">Shop</b-nav-item>
          <b-nav-item href="#" class="nav-links">Become a Member</b-nav-item>
          <b-nav-item href="#" class="nav-links">Donate</b-nav-item>
          <b-nav-item href="https://www.facebook.com/Pierce-County-Historical-Association-Pierce-County-Wisconsin-114492888569050" class="nav-links"><b-icon-facebook/></b-nav-item>
          <b-nav-item href="https://www.instagram.com/piercecountyhistory/" class="nav-links"><b-icon-instagram/></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <transition name="fade">

      <router-view/>

    </transition>
    <b-container fluid="true" id="footer">
      <b-row>
        <b-col sm="12" lg="4">
          <b>Office</b><br/>
          Pierce County Historical Association<br/>
          W6321 E. Main St<br/>
          Bay City, WI 54723<br/>
          <a href="tel:715-594-3118">715-594-3118</a> <br/>
        </b-col>
        <b-col sm="12" lg="4">
          <b>Get Involved</b><br/>
          Volunteer<br/>
          Donate
        </b-col>
        <b-col sm="12" lg="4">
          <b>Mailing Address</b><br/>
          Pierce County Historical Association<br/>
          PO Box 148<br/>
          Ellsworth, WI 54011 <br/>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="4">
          <a href="mailto:info@piercecountyhistorical.org">info@piercecountyhistorical.org</a>
        </b-col>
        <b-col sm="12" lg="4" style="alignment: center">
          All Rights Reserved
        </b-col>
        <b-col sm="12" lg="4"></b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
export default {
  name: 'PrimaryNav'
}
</script>

<style scoped>

a:link {
  color: black;
}

a:visited {
  color: black;
}

#footer {
  padding: 10px;
  font-size: 12px;
  background-color: wheat;
}

.nav-links {
  font-weight: bold;
}

</style>
