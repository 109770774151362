<template>
  <div>
    <b-card
        title="Hours"
        tag="article"
    >
      <b-card-text>
        Our Bay City office is open by appointment only.<br>
        Please call 715-594-3118.
      </b-card-text>
    </b-card>
    <br/>
    <b-card
        title="Events"
        tag="article"
    >
      <b-card-text>
        <b>Open Houses</b><br/>
        June 19th - 20th<br/>
        July 17th - 18th<br/>
        September 18th - 19th<br/>
        October 16th - 17th<br/>
        <br/>
        <b>Pierce County Fair</b><br/>
        We're at the Pierce Country Fair August 16th-20th at the Frier School.<br/>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Events'
}
</script>

<style scoped>

</style>
